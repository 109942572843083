#portfolio {
    background: #ebeeee;
    padding-top: 60px;
    padding-bottom: 60px;
    max-height: none;
}

#portfolio h3 {
    display: inline-block;
    border-bottom: 3px solid #11ABB0;
    font: 25px 'opensans-bold', sans-serif;
    margin-bottom: 40px;
}

/**
 * Popup Modal - (_layout.scss)
 * ------------------------------------------------------------------
 */
.popup-modal {
    max-width: 550px;
    background: #FFFFFF;
    position: relative;
    margin: 0 auto;
}

.popup-modal .media {
    position: relative;
}

.popup-modal img {
    vertical-align: bottom;
}

.popup-modal .description-box {
    padding: 1.8rem 3.6rem 3rem;
}

.popup-modal .description-box h4 {
    font-family: "poppins-bold", sans-serif;
    font-size: 1.5rem;
    line-height: 2.4rem;
    margin-bottom: .6rem;
}

.popup-modal .description-box p {
    font-family: "poppins-regular", sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
    margin-bottom: 12px;
}

.popup-modal .categories {
    font-family: "poppins-bold", sans-serif;
    font-size: 1.1rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    letter-spacing: .1rem;
    display: block;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);
}

.popup-modal .link-box {
    width: 100%;
    overflow: hidden;
    background: #000000;
}

.popup-modal .link-box a {
    font-family: "poppins-bold", sans-serif;
    font-size: 1.2rem;
    line-height: 6rem;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 3px;
    cursor: pointer;
    display: block;
    text-align: center;
    float: left;
    width: 50%;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.popup-modal .link-box a:first-child {
    border-right: 1px solid rgba(200, 200, 200, 0.1);
}

.popup-modal .link-box a:hover {
    background: #cc005f;
    border: none;
}

@media only screen and (max-width: 600px) {
    .popup-modal {
        width: auto;
        margin: 0 20px;
    }
}

/* ===================================================================
 * # portfolio
 *
 * ------------------------------------------------------------------- */

.works-list {
    display: inline-block;

    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;

    -webkit-column-width: 600px;
    -moz-column-width: 600px;
    column-width: 600px;
}

.works-list-item {
    display: block;
    max-width: 600px;
    height: 150px;
    margin-bottom: 5%;
    padding-right: 5%;
    padding-left: 5%;

    -webkit-column-break-inside: avoid;
}

.works-list-link {
    display: flex;
    position: relative;
    text-decoration: none;
    width: 100%;
    height: 100%;
}

.works-item-pic {
    width: 150px;
    height: 100%;
}

.works-item-text-container {
    margin-left: 5%;
    text-align: left;
    height: 100%;
    flex-grow: 1;
    border-top: 1px solid grey;
}

.works-item-category {
    color: grey;
    padding-top: 10px;
    font: 14px 'opensans-bold', sans-serif
}

.works-item-title {
    color: #313131;
    padding-top: 10px;
    font: 20px 'opensans-bold', sans-serif;
}

.expand-icon {
    float: right;
    padding: 2%;
    color: grey;
}

/* Transitions / hovering */
.works-list-link:focus,
.works-list-link:hover {
    opacity: 0.7;
    -webkit-filter: grayscale(50%);
}

